.linecontakt {
    width: 100%;
    background: #f1f1f1;
    height: 80px;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
    }
    &_mail {
        font-size: clamp(16px, 2vw, 24px);
        font-weight: 400;
        line-height: 100%;
        text-align: right;
        gap: 10px;
        transition: 300ms;
        &:hover {
            text-decoration: underline;
        }
        img {
            // width: 36px;
            height: 25.46px;
        }
    }
    &_fos {
        width: 243px;
        height: 80px;
        // font-size: clamp(12px,2vw,18px);
        font-size: 20px;

        font-weight: 700;
        line-height: 100%;
        text-align: left;
        background: #d43333;
        color: #ffffff;
        transition: 300ms;
        &:hover {
            background: #dd5555;
        }
    }
    .btn-1:after {
        
        width: 98%;
    }
}

.buttons {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover{
        // border: 2px solid #d43333;
    }
}

.buttons span {
    position: relative;
    transition: all 0.45s ease-Out;
}

.spin {
    width: 0;
    height: 0;
    opacity: 0;
    left: 70px;
    top: 20px;
    transform: rotate(0deg);
    background: none;
    position: absolute;
    transition: all 0.5s ease-Out;
}

.buttons:hover .spin {
    width: 200%;
    height: 500%;
    opacity: 1;
    left: -70px;
    top: -70px;
    background: #0c0f29;
    transform: rotate(80deg);
}

.buttons:hover span {
    color: #fff;
}
