
.advantages_filter {
    display: flex;
    gap: 73px;
    margin-bottom: 60px;
}
  
.advantages_filter p {
  cursor: pointer;
}

.advantages_filter_item {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #d43333;
  transition: 300ms;
}

.filter_item_black {
  color: #000;
}

.advantages {
  margin-bottom: 100px;
}

.advantages .container {
  padding: 80px 0 100px;
}

.with_visa_center_cards, .no_visa_center_cards {
  display: none;
}
  
.with_visa_center_cards.active {
  display: grid;
  grid-template-columns: calc(50% - 50px) calc(50% - 50px);
  gap: 100px;
}

.no_visa_center_cards.active {
  display: grid;
  grid-template-columns: calc(50% - 50px) calc(50% - 50px);
  gap: 100px;
}

.advantages:has(.no_visa_center_cards.active) {
  background: #0c0f29;
}

.filter_item_first {
  color: #fff;
}

.no_visa_center_cards.active .advantages_description, .no_visa_center_cards.active .card_title, .no_visa_center_cards.active .card_text {
  color: #fff;
}

.no_visa_center_cards.active .visa_center_card {
  border-top: 1px solid #fff;
}

.no_visa_center_cards.active .border_bottom {
  border-bottom: 1px solid #fff;
}

.no_visa_center_cards.active .accent_block {
  border: none;
}

.advantages_title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  color: #d43333;
  margin-bottom: 20px;
}

.advantages_description {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000;
  margin-bottom: 76px;
}

.card_number p {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #d43333;
}

.card_title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  margin-bottom: 16px;
}

.card_text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.visa_center_card {
  display: flex;
  gap: 16px;
  padding: 36px 76px;
  border-top: 1px solid #000;
  height: 204px;
}

.border_bottom {
  border-bottom: 1px solid #000;
}

.accent_block {
  border: none;
  display: block;
  padding: 0;
}
