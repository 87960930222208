.fosContact{
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgb(12 15 41 / 98%);;
    height: 100%;
    width: 0%;
    z-index: 999;
    overflow: hidden;
    transition: 300ms;
    &.active{
        width: 100%;

    }

    &_form{
        width: 521px;
        width: 521px;
        display: flex;
        flex-direction: column;
        gap: 37px;
        position: relative;
        h3{
            font-size: clamp(20px,3vw,32px);
            font-weight: 600;
            line-height: 39px;
            letter-spacing: 0em;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            color: #fff;
        }
    }

    &_button{
        font-size: clamp( 16px,2vw,18px);
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        height: 80px;
        width: 100%;

        background: none;
        color: #fff;
        // border: 3px solid #D43333;
    }

    &_inputBox{
        all:initial;
        &:-moz-placeholder{
          color: #fff;
        }
        height: 80px;
        width: calc(100% - 34px);
        border: 2px solid rgba(255, 255, 255, 0.6901960784);
        font-size: clamp(16px, 3vw, 24px);
        font-style: italic;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        padding-left: 30px;
        color: #fff;
    }
}
.button_close{
  position: absolute;
  color: #fff;
  font-size: 35px;
  right: 0;
  top: -5px;
}
.error{
    position: relative;
    &::after{
        content: "*Ошибка, неправильно введены данные";
        position: absolute;
        font-size: 1em;
        font-weight: 400;

        left: 78px;
        bottom: -23px;
        color: $red;
    }
}

/* Transparent Overlay */
  
.loading{
    display: flex;
    align-items: center;
    justify-content: center;
}
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: #fff 1.5em 0 0 0, #fff 1.1em 1.1em 0 0, #fff 0 1.5em 0 0, #fff -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, #fff 0 -1.5em 0 0, #fff 1.1em -1.1em 0 0;
    box-shadow: #fff 1.5em 0 0 0, #fff 1.1em 1.1em 0 0, #fff 0 1.5em 0 0, #fff -1.1em 1.1em 0 0, #fff -1.5em 0 0 0, #fff -1.1em -1.1em 0 0, #fff 0 -1.5em 0 0, #fff 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }