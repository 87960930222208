.activities {
    padding: 100px 0 175px;
}

.activities_choice {
    display: flex;
    gap: 30px;
    margin: 44px 0 85px;
}

.select_month {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    cursor: pointer;
    padding: 0 5px;
    transition: 0.1s;
}

.select_month:hover {
    border-bottom: 1px solid #d43333;
}

.select_month_active {
    font-weight: 700;
    color: #d43333;
    border-bottom: 1px solid #d43333;
}

.all_inform {
    display: grid;
    grid-template-columns: 35% calc(65% - 82px);
    gap: 82px;
}

.list_events {
    display: flex;
    flex-direction: column;
    gap: 52px;
    height: 520px;
    overflow-y: scroll;

}

/* Define the scrollbar style */
.list_events::-webkit-scrollbar {
    width: 4px;
}

/* Define the thumb style */
.list_events::-webkit-scrollbar-thumb {
    background: #d43333;
}

/* Define the track style */
.list_events::-webkit-scrollbar-track {
    background: #5f5f5f;
}

.list_events_card {
    display: flex;
    gap: 24px;
    transition: 0.3s;
    cursor: pointer;
}

.list_events_card:hover {
    background: rgb(212 51 51 / 15%);
}

.list_events_card_active {
    background: rgba(212, 51, 51, 0.1);
}

.event_card_inform {
    padding: 16px 0;
}

.activities_title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #000;
    }

.activities_location {
    display: flex;
    gap: 4px;
    margin-top: 12px;
}

.activities_location img {
    width: 20px;
    height: 20px;
}

.activities_main_picture {
    height: 146px;
    width: 140px;
    object-fit: cover;
}

.activities_place {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #000;
}

.event_title_data {
    display: flex;
}

.event_title {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 32px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 44px;
    width: 77%;
}

.event_description, .event_data {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    font-variant: small-caps;
    color: #000;
    margin-bottom: 100px;
}

.event_description {
    width: 90%;
}

.additional_inform {
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.event_location, .event_link {
    display: flex;
    gap: 17px;
}

.event_location img, .event_link img {
    width: 36px;
    height: 36px;
}

.event_place, .event_registration {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    font-variant: small-caps;
    color: #d43333;
}

.event_registration a {
    color: #d43333;
}