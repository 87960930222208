.popapType {
    background: #0c0f29;
    width: 100%;
    position: fixed;
    height: 0vh;
    transition: 300ms;
    left: 0;
    bottom: 0;
    z-index: 999;
    overflow: hidden;
    &_cheked{
        display: none;
    }
    &.active {
        height: 100vh;
        // padding: 35px 0px;
    }
    &_title {
        position: relative;
        height: 250px;
        width: 30%;
        justify-content: flex-start;
        &::after {
            content: attr(data-logo);
            color: #d43333;
            position: absolute;
            bottom: 65px;
            left: -35px;
            width: 100%;
            height: 100%;
            font-size: 300px;
            font-weight: 700;
            text-align: left;
            z-index: 1;
        }
        h2 {
            font-size: clamp(16px, 3vh, 32px);
            font-weight: 600;
            line-height: 39px;
            letter-spacing: 0em;
            text-align: left;
            text-transform: uppercase;
            color: #fff;
            z-index: 2;
        }
    }
    &_ps {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        position: absolute;
        z-index: 2;
        bottom: 34px;
        text-transform: uppercase;
        left: 155px;
    }

    &_content {
        display:  flex;
        gap: 80px;
        &_a {
            display: block;
            padding-top: 10px;
            color: $red;
            text-decoration: underline;
        }
        &_ul {
            padding: 20px 0px;
            color: #fff;
            padding-left: 24px;
            li {
                list-style-type: decimal;
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                padding: 10px 0px;
                &:has(span) {
                    position: relative;
                    & .-dop {
                        position: static;
                        display: inline-flex;
                        margin-left: 10px;
                        top: 0;
                        right: 0;
                        height: 25px;
                        width: 25px;
                        border: 2px solid #d43333;
                        color: #d43333;
                        border-radius: 50%;
                        cursor: pointer;
                        /* padding-top: 1px; */
                        /* padding-left: 1px; */
                        transition: 300ms;
    
                        &:hover + span {
                            max-height: 50vh;
                            transition: 300ms;
                            top: 30px;
                            left: -28px;
                            padding: 15px;
                            background: #000;
                            box-shadow: 5px 4px 11px #00000073;
                            opacity: 1;
                            pointer-events: none;
                        }
                    }
                }
                
                span {
                    opacity: 0;
                    position: absolute;
                    z-index: 5;
                    transition: 300ms;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                  
                    display: block;
                    overflow: hidden;
                    max-height: 0vh;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                }
                a {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }
        &_van {
            position: relative;
            padding-left: 60px;
            padding-top: 60px;
            &_mail {
                font-size: 32px;
                font-weight: 600;
                line-height: 39px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                a {
                    color: $red;
                    text-decoration: underline;
                }
            }
            &::after {
                content: "1";
                font-size: 96px;
                font-weight: 600;
                line-height: 117px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                position: absolute;
                top: 0;
                left: -10px;
            }
        }
        &_tu{
            position: relative;
            padding-left: 60px;
            padding-top: 140px;
            max-width: 20%;
            p{
                font-size: 18px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #ffffff94;
                b{
                    color: #fff;
                }
            }
            &::after {
                content: "2";
                font-size: 96px;
                font-weight: 600;
                line-height: 117px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                position: absolute;
                top: 0;
                left: -15px;
            }
        }

        &_tru{
            padding-left: 60px;
            padding-top: 60px;
            max-width: 40%;
            position: relative;
            &::after {
                content: "3";
                font-size: 96px;
                font-weight: 600;
                line-height: 117px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                position: absolute;
                top: 0;
                left: -24px;
            }
        }
    }
    &_worning{
        h3{
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: #D43333;

        }
        p{
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;

        }
    }
    &_close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 36px;
        height: 36px;
        transition: 300ms;
        z-index: 9;
        cursor: pointer;
        &::after,
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background: #fff;
            transition: 300ms;
        }
        &::after {
            transform: rotate(45deg) translate(12px, 8px);
        }
        &::before {
            transform: rotate(135deg) translate(10px, -13px);
        }
        &:hover {
            transform: rotateZ(180deg) ;
            &::after,
            &::before {
                background: #eb6b6be8;
            }
        }
    }
}
