.kontakts {
    overflow: hidden;

    &_content {
        &_content {
            display: grid;
            grid-template-columns: 40% 60%;
            padding: 30px 0;
        }
        a {
            transition: 300ms;
            &:hover {
                text-decoration: underline;

                text-decoration-color: $red;
            }
        }
        .t20 {
            font-weight: 400;
        }
        .t14 {
            padding-top: 10px;
            font-weight: 700;

            &.-min {
                font-weight: 400;
            }
        }
        padding: 50px 0px;
        &_text {
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        &_minBox {
            display: grid;
            grid-template-columns: 10% 90%;
            width: 90%;

            img {
                width: 24px;
                height: auto;
                filter: invert(48%) sepia(13%)
                    saturate(3207%) hue-rotate(323deg)
                    brightness(95%) contrast(80%);
            }
        }
    }
    img {
        max-height: 30px;
        max-width: 30px;
    }
    .map {
        height: 100%;
    }
}
