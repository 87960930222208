

.tabSlider{
    padding: 30px 0;
    justify-content: space-around;
}

.tabCeked{
    width: 380px;
    height: 155px;
    position: relative;

    cursor: pointer;
    transition: 300ms;
    &:hover{
        // box-shadow: 8px 6px 15px 2px rgba(66, 66, 66, 0.3568627451);
        // align-items: flex-end;
        &::after{
            // font-size: 190px;
            color: #ff6f6f;

        }
        .tabCeked_text{
        bottom: 0;
        }
    }
    &::after{
        content:attr(data-simvol);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 87%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFC5C5;
        text-transform: uppercase;
        z-index: 1;
        transition: 300ms;
        font-size: 128px;
        font-weight: 700;
        line-height: 156px;
        letter-spacing: 0em;
        filter: drop-shadow(1px 4px 1px rgba(149, 149, 149, 0.2));
    }
    &_text{
        text-transform: uppercase;
        font-size: clamp( 12px, 2vw, 18px);
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        z-index: 2;
        width: 100%;
        position: absolute;
        left: 0;
        transition: 300ms;
        bottom: 50%;

    }
}