.fease{
    position: relative;
    height: 100vh;
    background-image: url(../../public/img/fease.webp);
    background-repeat: no-repeat;
    // background-size: cover;
    animation: fonAnim 100s linear infinite;
    &_box{
        height: 100%;
        flex-direction: column;
        align-items: flex-start;

    }
    .h2{
        color: #fff;
        text-align: left;
        text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
        .-cen{
            font-size: clamp(26px, 7vw, 100px);
            padding-left: 10px;
        }
        .-min{
            text-transform:lowercase;
        }
    }
    &_ps{
        padding-top: 20px;
        color: #fff;
        font-weight: 500;
        font-size: clamp(20px, 4vw, 36px);
        text-transform: uppercase;
        text-shadow:1px 1px 2px rgb(0 0 0 / 30%);
        padding-left: 13px;

    }
    .linecontakt{
        position: absolute;
        bottom: 0;
        background: #000000AB;
        a{
            color: #fff;

        }
        img{
            filter: invert(1);
        }
    }
}   
@keyframes fonAnim {
    0%{
        // background-size: 130%;
        background-position: center left;
    }
    50%{
        // background-size: 110%;

        background-position: center right;

    }
    100%{
        // background-size: 130%;

        background-position: center left;

    }
}