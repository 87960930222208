.documents {
    height: 145vh;
    padding: 100px 0px;

    &_title {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        padding: 15px 0px;
    }
    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(2, minmax(50px, 1fr));
        grid-auto-flow: column;
        grid-template-rows: repeat(5, 1fr);

        gap: 22px;
        column-gap: 63px;
        padding: 40px 0px;
        width: 100%;

        li {
            list-style-type: decimal;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            max-width: 100%;
            margin-left: 30px;

            &:has(span) {
                position: relative;
                & .-dop {
                    // position: absolute;
                    top: 0;
                    right: 0;
                    height: 25px;
                    width: 25px;
                    border: 2px solid #d43333;
                    color: #d43333;
                    border-radius: 50%;
                    cursor: pointer;
                    padding-top: 1px;
                    padding-left: 1px;
                    transition: 300ms;
                    display: inline;
                    padding: 0px 6px;
                    margin-left: 20px;
                    &:hover ~ span {
                        max-height: 50vh;
                        transition: 300ms;
                        top: 30px;
                        left: -32px;
                        padding: 15px;
                        background: #f3f3f3;
                        box-shadow: 5px 4px 11px #00000073;
                        opacity: 1;
                    }
                }
            }
        }
        a {
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            text-decoration: underline;
            color: $red;
        }
        span {
            opacity: 0;
            position: absolute;
            z-index: 5;
            transition: 300ms;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;

            display: block;
            overflow: hidden;
            max-height: 0vh;
        }
    }

    &_warning {
        // max-width: 50%;
        background: #fff5f5;
        margin-top: 50px;
        padding: 20px;
        box-shadow: 4px 17px 27px -1px rgb(149 149 149 / 12%);

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
            color: #d43333;
        }
        p {
            font-size: 20px;
            font-weight: 600;
            line-height: 122%;
            letter-spacing: 0em;
            text-align: left;
            padding-top: 10px;
        }
    }
}
